<template>
  <ValidationProvider
    ref="fieldPaymentMethods"
    :name="$t(label)"
    :rules="required ? 'required' : ''"
    :vid="label"
    slim
  >
    <template #default="{ errors, valid }">
      <v-select
        v-model="iPaymentMethodId"
        :error-messages="errors"
        :items="items"
        :label="$t(label)"
        :loading="loading"
        :menu-props="{ offsetY: true }"
        :success="valid"
        clearable
        dense
        hide-details="auto"
        item-text="name"
        item-value="id"
        outlined
        v-bind="$attrs"
        @change="onChange"
      />
    </template>
  </ValidationProvider>
</template>
<script lang="ts">
import { PaymentMethodCollection } from "@planetadeleste/vue-mc-gw";
import { Component, Prop, VModel, Vue } from "vue-property-decorator";

@Component
export default class PaymentMethodSelect extends Vue {
  @VModel({ type: Number }) iPaymentMethodId!: number;
  @Prop({ type: String, default: "payment.method" }) readonly label!: string;
  @Prop(Boolean) readonly required!: boolean;
  @Prop(Boolean) readonly invoice!: boolean;
  @Prop(Boolean) readonly account!: boolean;

  obCollection: PaymentMethodCollection = new PaymentMethodCollection();

  get items() {
    return this.obCollection.models;
  }

  get loading() {
    return this.obCollection.loading;
  }

  mounted() {
    this.$nextTick(this.load);
  }

  async load() {
    this.obCollection.clear();

    if (this.invoice) {
      this.obCollection.inInvoice();
    }

    if (this.account) {
      this.obCollection.inAccount();
    }

    await this.obCollection.list();
  }

  onChange(sValue: number) {
    this.$emit("change", sValue);
  }
}
</script>
