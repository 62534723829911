<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12">
        <data-table
          :headers="headers"
          :items="obCollection.getModelList()"
          :last-page="serverLastPage"
          :loading="bLocalLoading"
          :options="pagination"
          :total="serverItemsLength"
          btn-action-item-key-id="id"
          btn-action-path="accounts"
          hide-btn-action-view
          is-route-name
          @delete="deleteItem"
          @update:sort-by="onSortBy"
          @update:sort-desc="onSortDesc"
          @update:options="onPagination"
          @update:page="onPage"
        >
          <template #[`item.name`]="{ item }">
            <v-btn
              :to="{ name: 'accounts.update', params: { id: item.id } }"
              color="primary"
              text
            >
              {{ item.name }}
            </v-btn>
          </template>

          <template #[`item.currencies`]="{ item }">
            <currency-list :currency-ids="item.currencies" />
          </template>

          <template #[`item.actions`]="{ item }">
            <module-actions
              :cannot-delete="!item.editable"
              :value="item.id"
              @delete="deleteItem"
            />
          </template>
        </data-table>
      </v-col>
    </v-row>
    <accounts-form
      :key="$route.params.id"
      v-model="obItem"
      :open.sync="displayForm"
    />
  </v-col>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import AccountsMixin from "@/modules/accounts/mixins/AccountsMixin";
import ActiveIcon from "@/components/common/ActiveIcon.vue";
import AccountsForm from "@/modules/accounts/components/Form.vue";
import type { DataTableHeader } from "vuetify";
import CurrencyPreview from "@/modules/currencies/components/CurrencyPreview.vue";
import CurrencyList from "@/modules/currencies/components/CurrencyList.vue";

@Component({
  components: {
    CurrencyList,
    CurrencyPreview,
    ActiveIcon,
    AccountsForm,
  },
})
export default class AccountsList extends Mixins(AccountsMixin) {
  isList = true;

  onMounted() {
    const arHeaders: DataTableHeader[] = [
      { text: "account.code", value: "code" },
      { text: "name", value: "name" },
      { text: "account.type", value: "paymentmethod.name" },
      { text: "currency", value: "currency.name" },
      { text: "accepted.currencies", value: "currencies" },
    ];
    this.setDTHeaders(arHeaders).addDTActionsHeader();
    this.index();
  }

  onRegisterEvents() {
    this.addEvent("app.company.selected", this.index);
  }
}
</script>
