<template>
  <v-dialog v-model="drawer" max-width="800" persistent scrollable>
    <v-form v-if="!!obModel && drawer" class="fill-height" @submit.prevent>
      <gw-form-observer
        :hide-save-action="!isDirty"
        :loading="isLoading"
        hide-top-actions
        lazy-validation
        @cancel="cancel"
        @save="save"
      >
        <settings v-model="obModel" />
      </gw-form-observer>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Mixins, PropSync } from "vue-property-decorator";
import AccountsMixin from "@/modules/accounts/mixins/AccountsMixin";
import Settings from "@/modules/accounts/components/tabs/Settings.vue";
import { GwCacheModule } from "@/store/cache";
import { AppModule } from "@/store/app";
import { AccountModule } from "@/store/account";

@Component({
  components: {
    Settings,
  },
})
export default class AccountsForm extends Mixins(AccountsMixin) {
  @PropSync("open", { type: Boolean, default: false }) drawer!: boolean;

  get isDirty(): boolean {
    return !!this.obModel && this.obModel.isDirty();
  }

  get company() {
    return AppModule.company;
  }

  onRegisterEvents() {
    this.addEvent(`${this.sModelName}.after.save`, this.cleanAccountCache);
    this.addEvent(`${this.sModelName}.after.delete`, this.cleanAccountCache);
  }

  async cleanAccountCache() {
    const sKey = `Account/${this.company.id}.models`;
    GwCacheModule.forget(sKey);
    await AccountModule.load({ company: this.company.id });
  }
}
</script>
