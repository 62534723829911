<template>
  <div :class="obClass" class="pde-active-icon">
    <icon-close-circle-outline v-if="!active && withError" />
    <icon-checkmark-done-circle-outline v-else />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

/**
 * Display and active/inactive icon based on "active" prop
 */
@Component
export default class ActiveIcon extends Vue {
  /**
   * Set active mark on/off
   * @type {boolean}
   * @default false
   */
  @Prop([Boolean, Number]) readonly active!: boolean;

  @Prop(Boolean) readonly withError!: boolean;

  get obClass() {
    return {
      active: this.active,
      alert: !this.active && this.withError,
    };
  }
}
</script>
